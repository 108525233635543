import React from "react";

const Intro3It = ({title, img, desc, links}) => {
  return (
    <header className="slider-stwo valign position-re">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="img">
              <img src={`${img}`} alt="" />
            </div>
          </div>
          <div className="col-lg-7 valign">
            <div className="cont">
                {/* <div className="sub-title mb-5">
                  <h6>{title}</h6>
                </div> */}
              <h1 className="mb-10 fw-600">{title}</h1>
              <p>
                {desc}
              </p>
              <ul>
                {
                    links.map((item, index) => (
                      <li className="p-2" key={index}>
                        <a href={item.id ? `#${item.id}` : ''} className={!item.id && 'disableLink'}>
                        <div>
                          <span className={`icon ${item.icon}`}>
                            <span className="bord"></span>
                          </span>
                        </div>
                        <div className="cont">
                          <h6>{item.name}</h6>
                          <p>
                          {item.desc}
                          </p>
                        </div>
                        </a>
                      </li>
                    ))
                  }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Intro3It;
