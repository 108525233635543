import React from "react";
// import { Link } from 'gatsby';

const Services2It = ({ contentdata }) => {
  return (
    <section
      className="services section-ptb position-re"
      id={`${contentdata.id}`}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="sec-head  text-center">
            <h4 className="words chars splitting main-title wow" data-splitting>
                {contentdata.title}
            </h4>
            </div>
          </div>
        </div>
        <div className={contentdata.title === "Our Domain Expertise" ? "row esdm-services" : 'row'}>
          {contentdata.subcategory.map((item, index) => (
            <div
              className="col-lg-4 wow fadeInUp"
              data-wow-delay={`${item.title}`}
            >
              <div className={`step-item ${item.color}`}>
                <span className={`icon ${item.icon}`}></span>
                <h6>{item.title}</h6>
                {/* <ul
                  className="smp-list ab--left-align"
                  dangerouslySetInnerHTML={{
                    __html: `<li>${item.desc.join("</li><li>")}</li>`,
                  }}
                ></ul> */}
                <ul
                    className="smp-list ab--left-align"
                    dangerouslySetInnerHTML={{
                      __html: `<li>${item.desc.join("</li><li>")}</li>`,
                    }}
                  ></ul>
                {
                  item.subSections && item.subSections.map((subItem,index) => {
                    return <>
                    <ul className="smp-list ab--left-align">
                      <li className="custom-font-wgt custom-listitem">{subItem.header}</li>
                    </ul>
                    <ul
                    className="smp-list ab--left-align custom_sublist"
                    dangerouslySetInnerHTML={{
                      __html: `<li>${subItem.desc.join("</li><li>")}</li>`,
                    }}
                  ></ul>
                    </>
                  })
                }
              </div>
            </div>
            // <li className="p-2">
            //   <div>
            //     <span className={`icon ${item.icon}`}>
            //       <span className="bord"></span>
            //     </span>
            //   </div>
            //   <div className="cont">
            //     <h6>{item.name}</h6>
            //     <p>
            //     {item.desc}
            //     </p>
            //   </div>
            // </li>
          ))}
          {/* <div className="col-lg-4 wow fadeInUp" data-wow-delay=".3s">
            <div className="step-item xtop">
              <span className="icon pe-7s-gleam"></span>
              <h6>Digital Marketing</h6>
              <p>
                Tempore corrupti temporibus fuga earum asperiores fugit
                laudantium.
              </p>
            </div>
          </div>
          <div className="col-lg-4 wow fadeInUp" data-wow-delay=".6s">
            <div className="step-item xcolor">
              <span className="icon pe-7s-phone"></span>
              <h6>Web &amp; App Development</h6>
              <p>
                Tempore corrupti temporibus fuga earum asperiores fugit
                laudantium.
              </p>
            </div>
          </div>
          <div className="col-lg-4 wow fadeInUp" data-wow-delay=".9s">
            <div className="step-item xbottom">
              <span className="icon pe-7s-magic-wand"></span>
              <h6>Graphic Design</h6>
              <p>
                Tempore corrupti temporibus fuga earum asperiores fugit
                laudantium.
              </p>
            </div>
          </div> */}
        </div>
        {/* <div className="smore">
          <Link to="/about/about-dark">
            Discover More
          </Link>
          <i className="fas fa-long-arrow-alt-right"></i>
        </div> */}
      </div>
      <div className="line bottom left"></div>
      {/* <div className="line bottom right"></div> */}
    </section>
  );
};

export default Services2It;
